import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '@core/services/api/api.service';
import {ApiV2EndpointsEnum} from '@core/enums/api-v2-endpoints.enum';
import {
    ProgressGroupAssignmentInterface,
    ProgressGroupInterface,
    ProgressInterface,
    ProgressItemInterface,
    ProgressItemSelfExamineInterface,
    ProgressSelfExamineInterface,
    ProgressUserInterface,
    ProgressUserProgressionInterface,
    ProgressValuesGroupInterface,
    ProgressValuesInterface,
} from '@shared/interfaces/progress.interface';
import {sprintf} from '@core/core.functions';
import {ProgressTypeEnum} from '@feature/progress/enums/progress.type.enum';

export interface ProgressRequiresExamineInterface {
    progressUserProgress: ProgressInterface;
    userProgressItem: ProgressItemInterface;
}

@Injectable({
    providedIn: 'root',
})
export class ProgressService {
    #apiService: ApiService = inject(ApiService);

    progression(bookUuid: string, chapterUuid?: string): Observable<ProgressUserProgressionInterface> {
        const urlSearchParams: URLSearchParams = new URLSearchParams();

        if (undefined !== chapterUuid) {
            urlSearchParams.set('chapterUuid', chapterUuid);
        }

        return this.#apiService.get(sprintf(ApiV2EndpointsEnum.ProgressUserProgression, bookUuid), urlSearchParams);
    }

    saveReview(bookUuid: string, chapterUuid: string, groupId: number, userId: number, assignmentDocumentDpsId: string, grade: number): Observable<ProgressGroupAssignmentInterface> {
        return this.#apiService.post(sprintf(ApiV2EndpointsEnum.ProgressReview, bookUuid, chapterUuid, groupId, userId, assignmentDocumentDpsId), {grade});
    }

    saveUpdatedReview(bookUuid: string, chapterUuid: string, groupId: number, userId: number, assignmentDocumentDpsId: string, grade: number): Observable<ProgressGroupAssignmentInterface> {
        return this.#apiService.post(sprintf(ApiV2EndpointsEnum.ProgressUpdatedReview, bookUuid, chapterUuid, groupId, userId, assignmentDocumentDpsId), {grade});
    }

    removeAnswers(bookUuid: string, chapterUuid: string, groupId: number, userId: number, assignmentDocumentDpsId: string): Observable<ProgressGroupAssignmentInterface> {
        return this.#apiService.delete(sprintf(ApiV2EndpointsEnum.ProgressRemoveAnswers, bookUuid, chapterUuid, groupId, userId, assignmentDocumentDpsId));
    }

    retrieveUserProgress(bookUuid: string, chapterUuid: string, userId: number): Observable<ProgressUserInterface> {
        return this.#apiService.get<ProgressUserInterface>(sprintf(ApiV2EndpointsEnum.ProgressUser, bookUuid, chapterUuid, userId));
    }

    retrieveGroupProgress(bookUuid: string, chapterUuid: string, groupId: number): Observable<ProgressGroupInterface> {
        return this.#apiService.get<ProgressGroupInterface>(sprintf(ApiV2EndpointsEnum.ProgressGroup, bookUuid, chapterUuid, groupId));
    }

    getValuesByType(progressValues: ProgressValuesGroupInterface[], progressType: ProgressTypeEnum): ProgressValuesInterface | undefined {
        return progressValues.find(values => progressType === values.type);
    }

    requiresExamine(requiresExamine: ProgressRequiresExamineInterface): boolean {
        const progressUserProgress: ProgressInterface | ProgressSelfExamineInterface = requiresExamine.progressUserProgress;
        const userProgressItem: ProgressItemInterface | ProgressItemSelfExamineInterface = requiresExamine.userProgressItem;

        if (!this.#isProgressSelfExamineInterface(progressUserProgress) || !this.#isProgressItemSelfExamineInterface(userProgressItem)) {
            return false;
        }

        return !progressUserProgress.selfExamine && userProgressItem.sessionActive && 0 === userProgressItem.values.progress;
    }

    #isProgressSelfExamineInterface(progress: ProgressInterface | ProgressSelfExamineInterface): progress is ProgressSelfExamineInterface {
        return ('selfExamine' in progress);
    }

    #isProgressItemSelfExamineInterface(progressItem: ProgressItemInterface | ProgressItemSelfExamineInterface): progressItem is ProgressItemSelfExamineInterface {
        return ('sessionActive' in progressItem);
    }
}
