import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {PaginationControlsDirective} from 'ngx-pagination';

export interface PaginationButton {
    value?: string;
    disabled?: boolean;
    hidden?: boolean;
    visible?: boolean;
    translateParams?: any;
}

export interface PaginationPageButton extends PaginationButton {
    index: number;
    hasAnswer: boolean;
    inProgress: boolean;
    isTeacher: boolean;
}

export enum PaginationEventType {
    Page,
    Previous,
    Next,
}

export interface PaginationMappedPageEvent {
    button: PaginationPageButton;
    type: PaginationEventType;
    pageChanged: boolean;
}

export enum PaginationMode {
    Default = 'default',
    Design = 'design',
}

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements AfterViewInit, OnChanges {
    readonly modes = PaginationMode;

    @Output()
    mappedPageEvent: EventEmitter<PaginationMappedPageEvent> = new EventEmitter<PaginationMappedPageEvent>();

    @Input()
    mapping: PaginationPageButton[] = [];

    @Input()
    paginationApi!: PaginationControlsDirective;

    @Input()
    title?: string;

    @Input()
    hidePages: boolean = false;

    @Input()
    previousButton?: PaginationButton;

    @Input()
    nextButton?: PaginationButton;

    @Input()
    moduleColor: boolean = false;

    @Input()
    mode: PaginationMode = PaginationMode.Default;

    currentPage: number = 0;

    firstChange = true;

    private hasAnswerUpdates = new Map<number, boolean>();

    extendedPages: Array<{ label: string; value: any; hasAnswer: boolean; inProgress: boolean; isTeacher: boolean }> = [];

    ngAfterViewInit() {
        this.currentPage = this.paginationApi.getCurrent();
        this.updateExtendedPages();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateExtendedPages();
    }

    private updateExtendedPages(): void {
        this.extendedPages = this.paginationApi.pages.map((page) => {
            if (page.label !== '...') {
                const mappingItem = this.mapping.find((item) => item.index + 1 === page.value);
                const hasAnswer = mappingItem?.hasAnswer ?? false;
                const inProgress = mappingItem?.inProgress ?? false;
                const isTeacher = mappingItem?.isTeacher ?? false;

                const updatedHasAnswer = Number(page.label) === 1 ? true : (this.hasAnswerUpdates.get(page.value) ?? mappingItem?.hasAnswer ?? false);

                return {...page, hasAnswer: this.firstChange ? hasAnswer : updatedHasAnswer, inProgress, isTeacher};
            } else {
                return {...page, hasAnswer: true, inProgress: false, isTeacher: false};
            }
        });
    }

    handlePreviousClick(): void {
        const paginationApi: PaginationControlsDirective = this.paginationApi;
        const currentPage: number = this.paginationApi.getCurrent();
        const isFirstPage: boolean = paginationApi.isFirstPage();

        if (!isFirstPage) {
            paginationApi.previous();
        }

        this.#emitMappedPageEvent(isFirstPage ? currentPage : currentPage - 1, PaginationEventType.Previous, !isFirstPage);
    }

    handlePageClick(page: string): void {
        const pageNumber: number = Number(page);
        const mapping: PaginationPageButton | undefined = this.findMappingByIndex(pageNumber - 1);

        if (undefined !== mapping && mapping.disabled) {
            return;
        }

        const paginationApi: PaginationControlsDirective = this.paginationApi;
        const pageChanged: boolean = pageNumber !== paginationApi.getCurrent();

        if (pageChanged) {
            this.paginationApi.setCurrent(pageNumber);
        }

        if (mapping && pageChanged) {
            this.hasAnswerUpdates.set(pageNumber, true);
            this.firstChange = false;
        }

        this.#emitMappedPageEvent(pageNumber, PaginationEventType.Page, pageChanged);
    }

    handleNextClick(): void {
        const paginationApi: PaginationControlsDirective = this.paginationApi;
        const currentPage: number = this.paginationApi.getCurrent();
        const isLastPage: boolean = paginationApi.isLastPage();

        if (!isLastPage) {
            paginationApi.next();
        }

        this.#emitMappedPageEvent(isLastPage ? currentPage : currentPage + 1, PaginationEventType.Next, !isLastPage);
    }

    findMappingByIndex(pageIndex: number): PaginationPageButton | undefined {
        return this.mapping.find((mapped: PaginationPageButton): boolean => mapped.index === pageIndex);
    }

    #emitMappedPageEvent(page: number, type: PaginationEventType, pageChanged: boolean): void {
        const mapping: PaginationPageButton | undefined = this.findMappingByIndex(page - 1);

        if (undefined === mapping) {
            return;
        }

        this.mappedPageEvent.next({button: mapping, type, pageChanged});
    }
}
