import {
    ProgressInterface,
    ProgressItemExamInterface,
    ProgressItemNumberedInterface,
    ProgressUserInterface,
    ProgressUserItemAssignmentInterface,
    ProgressValuesItemsInterface,
} from '@shared/interfaces/progress.interface';
import {UserInterface} from '@shared/interfaces/user.interface';
import {ProgressTypeEnum} from '@feature/progress/enums/progress.type.enum';

export class ProgressHelper {
    public static getUsersProgressByTypes(progressTypes: ProgressTypeEnum[], progressUser: ProgressUserInterface): ProgressUserInterface {
        const mappedProgressUser: ProgressUserInterface = {...progressUser, progress: []};

        progressTypes.forEach(progressType => {
            const progress: ProgressInterface | undefined = this.getUserDataProgressByType(progressUser, progressType);

            if (undefined !== progress) {
                mappedProgressUser.progress.push({
                    ...progress, items: progress.items.map(item => {
                        return {...item, user: progressUser.user};
                    }),
                });
            }
        });

        return mappedProgressUser;
    }

    public static GroupUsersProgressByTypes(progressTypes: ProgressTypeEnum[], progressUsers?: ProgressUserInterface[]): ProgressUserInterface[] | undefined {
        const mappedProgressUsers: ProgressUserInterface[] = [];

        progressUsers?.forEach(progressUser => mappedProgressUsers.push(this.getUsersProgressByTypes(progressTypes, progressUser)));

        return mappedProgressUsers ?? undefined;
    }

    public static getAssignmentProgress(progress?: ProgressUserInterface[], assignmentType: ProgressTypeEnum = ProgressTypeEnum.Assignment): ProgressInterface<ProgressUserItemAssignmentInterface>[] | undefined {
        const assignmentProgress: ProgressInterface<ProgressUserItemAssignmentInterface>[] = [];

        progress?.forEach(progressUser => {
            const progress: ProgressInterface | undefined = this.getUserDataProgressByType(progressUser, assignmentType);

            if (undefined !== progress && this.isAssignmentProgress(progress, assignmentType)) {
                assignmentProgress.push({
                    ...progress, items: progress.items.map(item => {
                        return {...item, user: progressUser.user};
                    }),
                });
            }
        });

        return assignmentProgress ?? undefined;
    }

    /**
     * @deprecated this should no longer be required when backend determines order of items
     */
    public static getExamProgress(progress?: ProgressUserInterface[]): Map<ProgressTypeEnum, ProgressUserInterface[]> {
        const userExamProgress: Map<ProgressTypeEnum, ProgressUserInterface[]> = new Map([[ProgressTypeEnum.Exam, []], [ProgressTypeEnum.RemindoExam, []]]);

        progress?.forEach((progressUser: ProgressUserInterface) => {
            const user: UserInterface = progressUser.user;

            const remindoProgress: ProgressInterface | undefined = this.getUserDataProgressByType(progressUser, ProgressTypeEnum.RemindoExam);

            if (undefined !== remindoProgress) {
                userExamProgress.get(ProgressTypeEnum.RemindoExam)?.push({user, progress: [remindoProgress]});
            }

            const examProgress: ProgressInterface | undefined = this.getUserDataProgressByType(progressUser, ProgressTypeEnum.Exam);

            if (undefined !== examProgress) {
                userExamProgress.get(ProgressTypeEnum.Exam)?.push({user, progress: [examProgress]});
            }
        });

        return userExamProgress;
    }

    public static getProgressUserAssignmentProgressByUser(user?: UserInterface, progressUsers?: ProgressUserInterface[]): ProgressInterface<ProgressItemNumberedInterface> {
        const progress: ProgressInterface = this.getProgressUserByProgressTypeAndUser(ProgressTypeEnum.Assignment, user, progressUsers);

        return progress as ProgressInterface<ProgressItemNumberedInterface>;
    }

    public static getProgressUserExamProgressByTypeAndUser(
        type: ProgressTypeEnum.Exam | ProgressTypeEnum.RemindoExam,
        user?: UserInterface,
        progressUsers?: ProgressUserInterface[],
    ): ProgressInterface<ProgressItemExamInterface, ProgressValuesItemsInterface<ProgressItemExamInterface>> {
        const progress: ProgressInterface = this.getProgressUserByProgressTypeAndUser(type, user, progressUsers);

        return progress as ProgressInterface<ProgressItemExamInterface, ProgressValuesItemsInterface<ProgressItemExamInterface>>;
    }

    /**
     * No need for validating the returned data, we can safely assume the data is of the correct type when it was found by type
     */
    private static getProgressUserByProgressTypeAndUser(type: ProgressTypeEnum, user?: UserInterface, progressUsers?: ProgressUserInterface[]): ProgressInterface {
        if (undefined === user) {
            throw new Error(`Unable to get user progress by type, user is undefined`);
        }

        const progressUser: ProgressUserInterface | undefined = progressUsers?.find(progressUser => user?.id === progressUser.user.id);

        if (undefined === progressUser) {
            throw new Error('Unable to get user progress by type, user has no progress data');
        }

        const progress: ProgressInterface | undefined = this.getUserDataProgressByType(progressUser, type);

        if (undefined === progress) {
            throw new Error(`Unable to get user progress by type, user has no progress for "${type}"`);
        }

        return progress;
    }

    private static isAssignmentProgress(progress: ProgressInterface, assignmentType: ProgressTypeEnum = ProgressTypeEnum.Assignment): progress is ProgressInterface<ProgressItemNumberedInterface> {
        return progress.type === assignmentType && (0 === progress.items.length || progress.items[0].hasOwnProperty('number'));
    }

    private static getUserDataProgressByType(progressUser: ProgressUserInterface, progressType: ProgressTypeEnum): ProgressInterface | undefined {
        return progressUser.progress.find(progress => progressType === progress.type && 0 !== progress.items.length);
    }
}
