import {AfterViewInit, Component, ElementRef, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import hljs from 'highlight.js';

@Component({
    selector: 'app-code-block',
    templateUrl: 'code-block.component.html',
    styleUrls: ['code-block.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CodeBlockComponent implements AfterViewInit {
    @ViewChild('code') readonly codeElement!: ElementRef;

    @Input() language: string = '';

    ngAfterViewInit(): void {
        const code = this.codeElement.nativeElement.textContent || '';
        const language = this.language.replace(/\s+/g, '');

        const escapedCode = code
            .replace(/@ldoublebrace;/g, '{{')
            .replace(/@rdoublebrace;/g, '}}')
            .replace(/@#60;/g, '<')
            .replace(/@#62;/g, '>');

        this.codeElement.nativeElement.innerHTML = hljs.highlight(escapedCode, {language}).value;
    }

    private escapeHtml(html: string): string {
        return html.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    }
}
