import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreFeatureSelectorEnum} from '@store/store.enum';
import {ProgressState} from '@store/progress/progress.reducer';
import {ProgressGroupInterface, ProgressItemNumberedInterface, ProgressUserInterface} from '@shared/interfaces/progress.interface';
import {ProgressHelper} from '@store/progress/progress.helpers';
import {UserAuthenticatedInterface, UserInterface} from '@shared/interfaces/user.interface';
import {SelectionSelectors} from '@store/selection';
import {SecuritySelectors} from '@store/security';
import {ProgressTypeEnum} from '@feature/progress/enums/progress.type.enum';

export class ProgressSelectors {
    static readonly selectUserOrCurrentUser = createSelector(
        SelectionSelectors.selectUser,
        SecuritySelectors.selectCurrentUser,
        (user?: UserInterface, currentUser?: UserAuthenticatedInterface) => user ?? currentUser,
    )

    static readonly getProgressState = createFeatureSelector<ProgressState>(
        StoreFeatureSelectorEnum.Progress,
    );

    // Page selectors
    static readonly selectPage = createSelector(
        ProgressSelectors.getProgressState,
        (state: ProgressState) => state.page,
    );

    // User assignment selectors
    static readonly selectAssignment = createSelector(
        ProgressSelectors.getProgressState,
        (state: ProgressState) => state.assignment,
    );

    static readonly selectUserAndAssignment = createSelector(
        SelectionSelectors.selectUser,
        ProgressSelectors.selectAssignment,
        (user?: UserInterface, assignment?: ProgressItemNumberedInterface) => {
            return {user, assignment};
        },
    );

    // User store selectors
    static readonly selectProgression = createSelector(
        ProgressSelectors.getProgressState,
        (state: ProgressState) => state.progression,
    );

    static readonly selectUserProgress = createSelector(
        ProgressSelectors.getProgressState,
        (state: ProgressState) => state.user,
    );

    static readonly selectUserProgressByTypes = (types: ProgressTypeEnum[]) => createSelector(
        ProgressSelectors.selectUserProgress,
        (progressUser?: ProgressUserInterface) => {
            return undefined !== progressUser ? ProgressHelper.getUsersProgressByTypes(types, progressUser) : undefined;
        },
    );

    // Group store selectors
    static readonly selectGroupProgress = createSelector(
        ProgressSelectors.getProgressState,
        (state: ProgressState) => state.group,
    );

    static readonly selectGroupProgressUserProgress = createSelector(
        ProgressSelectors.selectGroupProgress,
        (group?: ProgressGroupInterface) => group?.progress,
    );

    static readonly selectGroupProgressUserExamProgressByType = (type: ProgressTypeEnum.Exam | ProgressTypeEnum.RemindoExam) => createSelector(
        [SelectionSelectors.selectUser, ProgressSelectors.selectGroupProgressUserProgress],
        (student?: UserInterface, progressUsers?: ProgressUserInterface[]) => ProgressHelper.getProgressUserExamProgressByTypeAndUser(type, student, progressUsers),
    );

    static readonly selectGroupProgressUserAssignmentProgress = createSelector(
        [SelectionSelectors.selectUser, ProgressSelectors.selectGroupProgressUserProgress],
        (student?: UserInterface, progressUsers?: ProgressUserInterface[]) => ProgressHelper.getProgressUserAssignmentProgressByUser(student, progressUsers),
    );

    static readonly selectGroupUsersAssignmentProgress = createSelector(
        ProgressSelectors.selectGroupProgressUserProgress,
        (progress?: ProgressUserInterface[]) => ProgressHelper.getAssignmentProgress(progress),
    );

    static readonly selectGroupUsersAlgebraKitProgress = createSelector(
        ProgressSelectors.selectGroupProgressUserProgress,
        (progress?: ProgressUserInterface[]) => ProgressHelper.getAssignmentProgress(progress, ProgressTypeEnum.Algebrakit),
    );

    static readonly selectGroupUsersDrillsterProgress = createSelector(
        ProgressSelectors.selectGroupProgressUserProgress,
        (progress?: ProgressUserInterface[]) => ProgressHelper.getAssignmentProgress(progress, ProgressTypeEnum.Drillster),
    );
}
